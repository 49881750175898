import React from 'react'
import appStore from '../../assets/images/download-appstore.svg'
import playStore from '../../assets/images/download-playstore.svg'
import { APP_STORE, PLAY_STORE } from '../../config/current'
import './index.scss'

const iosButtonTrackingName = 'download-ios-app'
export const iosButtonAlt = 'Download from the App Store'

const androidButtonTrackingName = 'download-android-app'
export const androidButtonAlt = 'Get it on Google Play'

const trackClick = (position, buttonId) => {
  window.ysAnalytics.trackClick({
    label: buttonId,
    name: buttonId,
    type: 'button',
    position: position,
  })
}

export const trackPlayStoreClick = function (position) {
  trackClick(position, androidButtonTrackingName)
}

export const trackAppStoreClick = function (position) {
  trackClick(position, iosButtonTrackingName)
}

const AppDownloadButtons = ({ tracking: { position } = {} }) => {
  return (
    <div className="appButtons__buttons">
      <a
        href={APP_STORE}
        rel="external nofollow"
        onClick={() => trackAppStoreClick(position)}
        onAuxClick={() => trackAppStoreClick(position)}
      >
        <img className="appButtons__button" src={appStore} alt={iosButtonAlt} />
      </a>
      <a
        href={PLAY_STORE}
        rel="external nofollow"
        onClick={() => trackPlayStoreClick(position)}
        onAuxClick={() => trackPlayStoreClick(position)}
      >
        <img
          className="appButtons__button"
          src={playStore}
          alt={androidButtonAlt}
        />
      </a>
    </div>
  )
}

export default AppDownloadButtons
