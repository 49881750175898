import React from 'react'
import styled from 'styled-components'
import { ReactComponent as GuitarTunaLogo } from '../../assets/images/gt-logo.svg'
import { color } from './constants/colors'
import { media } from './constants/breakpoints'
import { ReactComponent as StarFilled } from '../../assets/images/yellow-star-filled.svg'
import { ReactComponent as StarEmpty } from '../../assets/images/yellow-star-half.svg'
import { ReactComponent as PlayStoreImg } from '../../assets/images/download-playstore.svg'
import { ReactComponent as AppStoreImg } from '../../assets/images/download-appstore.svg'
import { APP_STORE, PLAY_STORE } from '../../config/current'
import {
  trackPlayStoreClick,
  trackAppStoreClick,
  androidButtonAlt,
  iosButtonAlt,
} from '../AppDownloadButtons'

const Root = styled.div`
  display: none;
  ${media.desktop`
    display: grid;
  `};
  color: ${color.greylight};
  text-align: left;
  gap: 0.5rem;
  font-size: 1em;
`

const MainText = styled.span``

const SubText = styled.span`
  color: ${color.greysemilight};
  font-size: 0.875em;
`

const IconLink = styled.a`
  width: min-content;
`

const Icon = styled(GuitarTunaLogo)`
  margin-top: -0.5rem;
  margin-left: -0.5rem;
`

const Stars = styled.div`
  margin: 1em 0 0.5em;
  font-size: 1em;
  font-weight: 500;
  display: grid;
  grid-auto-flow: column;
  gap: 0.2em;
  grid-auto-columns: min-content;

  svg:first-child {
    margin-left: 0.4em;
  }

  svg {
    width: 1em;
  }
`
const AppButtons = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: 1rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const PlayStore = styled(PlayStoreImg)`
  margin-top: -0.5rem;
  margin-left: -0.5rem;
`
const AppStore = styled(AppStoreImg)`
  margin-top: -0.5rem;
  margin-left: -0.5rem;
`

function AppInfo() {
  const position = 'tuner-section-app-info'

  return (
    <Root>
      <IconLink href={APP_STORE}>
        <Icon alt="GuitarTuna app logo" />
      </IconLink>
      <MainText>GuitarTuna - Chords, Tuner, Songs</MainText>
      <SubText>Guitar, Bass, Ukulele & more</SubText>
      <Stars>
        4.8
        <StarFilled alt="Filled star" />
        <StarFilled alt="Filled star" />
        <StarFilled alt="Filled star" />
        <StarFilled alt="Filled star" />
        <StarEmpty alt="Half-empty star" />
      </Stars>
      <AppButtons>
        <a
          href={APP_STORE}
          rel="external nofollow"
          onClick={() => trackAppStoreClick(position)}
          onAuxClick={() => trackAppStoreClick(position)}
          alt={iosButtonAlt}
        >
          <AppStore alt="Download on the App Store" />
        </a>
        <a
          href={PLAY_STORE}
          rel="external nofollow"
          onClick={() => trackPlayStoreClick(position)}
          onAuxClick={() => trackPlayStoreClick(position)}
          alt={androidButtonAlt}
        >
          <PlayStore alt="Download on the Play Store" />
        </a>
      </AppButtons>
    </Root>
  )
}

export default AppInfo
