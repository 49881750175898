import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.a`
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  display: inline-grid;
  place-content: center;
  white-space: nowrap;
  background-color: #00d296;
  color: #1b1c1e;
  border: 2px solid #00d296;
  border-radius: 50px;
  transition: all 0.1s ease-out;

  &:hover {
    background-color: #1b1c1e;
    color: #00d296;
    transform: scale(1.02);
  }
`

const Button = ({
  href = 'https://yousician.com/',
  tracking: { label, name, position, type } = {},
  children,
  ...props
}) => {
  const trackClickEvent = () =>
    window.ysAnalytics.trackClick({
      label,
      name,
      position,
      type,
    })

  return (
    <StyledButton href={href} onClick={trackClickEvent} onAuxClick={trackClickEvent} {...props}>
      {children}
    </StyledButton>
  )
}
export default Button
