import React, { useState } from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import image from '../../assets/images/app-songs-pick.jpg'
import './index.scss'

const ImageTextPick = () => {
  const [buttonsVisible] = useState(true)

  return (
    <section className="imageTextPick">
      <div className="imageTextPick__image" data-aspect-ratio="1">
        <div className="imageTextPick__imageBox">
          <img src={image} srcSet="" alt="Yousician" />
        </div>
      </div>
      <div className="imageTextPick__textContainer">
        <div className="imageTextPick__textArea">
          <h2 className="imageTextPick__heading">
            Get in tune and play songs you love with the #1 app
          </h2>
          <div className="imageTextPick__text">
            <p>
              Download the GuitarTuna app to access thousands of chords, tabs,
              and lyrics in our song library. Now on iOS and Android. Start for
              free.
            </p>
          </div>
          {buttonsVisible && (
            <AppDownloadButtons
              tracking={{
                position: 'image-text-pick',
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageTextPick
