import React, { useState } from 'react'
import Button from '../Button'
import image from '../../assets/images/image.png'
import logo from '../../assets/images/circle-purple.svg'
import './index.scss'

const ImageTextYousician = () => {
    const [buttonVisible] = useState(true)

    return (
        <section className="imageTextYousician">
            <div className="imageTextYousician__container">
                <div className="imageTextYousician__image">
                    <img src={image} srcSet="" alt="Yousician" />
                </div>
                <div className="imageTextYousician__textContainer">
                    <div className="imageTextYousician__textArea">
                        <h2 className="imageTextYousician__heading">
                            Learn to play guitar the fun and easy way with
                            Yousician
                        </h2>
                        <div className="imageTextYousician__text">
                            <p>
                                Level up your playing with lessons created by
                                real music teachers. Learn fast with interactive
                                tutorials and progress tracking.
                            </p>
                        </div>
                        {buttonVisible && (
                            <Button
                                tracking={{
                                    name: 'learn-more',
                                    position: 'yousician-promo',
                                    type: 'button',
                                    label: 'Learn more',
                                }}
                            >
                                Learn more{' '}
                                <span className="imageTextYousician__ctaArrow"></span>
                            </Button>
                        )}
                    </div>
                    <div className="imageTextYousician__logoArea">
                        <img
                            className="imageTextYousician__logoImg"
                            src={logo}
                            alt="Yousician logo"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageTextYousician
