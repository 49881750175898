const CONFIG = {
  name: 'Prod',
  env: 'prod',
  localApiAddress: 'http://localhost/api',
  apiAddress: 'https://api.yousician.com',
  frontAddress: 'https://yousician.com/guitartuna',
  frontRootAddress: 'https://yousician.com',
}

export default CONFIG

export const GA_TRACKING_ID = 'UA-36486027-12'
export const PREMIUM_LINK = 'https://yousician.onelink.me/7MHE/yshphf'
export const DOWNLOAD_LINK = 'https://guitartuna.onelink.me/QVz5/hpfcas'

export const APP_STORE = 'https://guitartuna.onelink.me/QVz5/vetu77wc'
export const PLAY_STORE = ' https://guitartuna.onelink.me/QVz5/roh0v4rh'

export const GUITAR_TUNER = 'https://yousician.com/guitar-tuner'
export const UKULELE_TUNER = 'https://yousician.com/ukulele-tuner'
export const VIOLIN_TUNER = 'https://yousician.com/violin-tuner'
export const BASS_TUNER = 'https://yousician.com/bass-tuner'
export const MANDOLIN_TUNER = 'https://yousician.com/mandolin-tuner'
export const BANJO_TUNER = 'https://yousician.com/banjo-tuner'

export const SONG_CHORDS = 'https://yousician.com/chords'

export const BLOG_POSTS = 'https://yousician.com/wp-json/ys/v1/webtuna_posts'
