import React, { useState } from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import image from '../../assets/images/guitarist-2.png'
import phoneCase from '../../assets/images/phone_border-vert.png'
import phoneImage from '../../assets/images/app-tools.jpg'
import './index.scss'

const ImageTextPhone = () => {
  const [phoneContentType] = useState('image')
  const [buttonsVisible] = useState(true)

  return (
    <section className="imageTextPhoneReversed">
      <div className="imageTextPhoneReversed__image">
        <div className="imageTextPhoneReversed__phone" data-aspect-ratio="0.48">
          <img
            className="imageTextPhoneReversed__phone"
            src={phoneCase}
            srcSet=""
            alt="Phone case"
          />
          {phoneContentType === 'image' ? (
            <img
              className="imageTextPhoneReversed__phoneContent"
              src={phoneImage}
              srcSet=""
              alt="GuitarTuna"
            />
          ) : (
            <video
              className="imageTextPhoneReversed__phoneContent"
              poster=""
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source src="" type="video/mp4"></source>
            </video>
          )}
        </div>
        <div className="imageTextPhoneReversed__imageBox">
          <img src={image} srcSet="" alt="GuitarTuna" />
        </div>
      </div>
      <div className="imageTextPhoneReversed__textContainer">
        <div className="imageTextPhoneReversed__textArea">
          <h2 className="imageTextPhoneReversed__heading">
            Practice like a pro with everything you need
          </h2>
          <div className="imageTextPhoneReversed__text">
            <p>
              Play better with the all-in-one app. Access chords, tunings,
              trainers, metronome, and more. Practice any chord, even without
              your guitar, with fun and interactive chord games.
            </p>
          </div>
          {buttonsVisible && (
            <AppDownloadButtons
              tracking={{
                position: 'image-text-phone',
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageTextPhone
