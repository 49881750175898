import React from 'react'
import styled from 'styled-components'
import Dialog from './Dialog'
import icon from '../../assets/images/error.svg'

const ErrorIcon = styled.img`
  margin-bottom: 1rem;
`

const ErrorDialog = ({ title = 'Oops!', message, close }) => {
  return (
    <Dialog
      id="error-dialog"
      header={{}}
      actions={{
        primary: {
          label: 'Dismiss',
          onClick: () => close(),
        },
      }}
    >
      <ErrorIcon src={icon} alt="GuitarTuna" />
      <h2>{title}</h2>
      <p>{message}</p>
    </Dialog>
  )
}

export default ErrorDialog
