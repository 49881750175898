import React from 'react'

import Modal from '../Modal'
import TuningOptions from '../Tunings'

const TuningsModal = ({ selectedTuning, handleSelect, close, children }) => {
    return (
        <Modal id="tunings-modal" close={close}>
            <TuningOptions
                selectedTuning={selectedTuning}
                handleSelect={handleSelect}
                className={children ? 'invisible' : 'visible'}
            />
            {children}
        </Modal>
    )
}

export default TuningsModal
