import React, { useState } from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import image from '../../assets/images/guitarist-1.png'
import phoneCase from '../../assets/images/phone_border-vert.png'
import phoneImage from '../../assets/images/app-songs.jpg'
import './index.scss'

const ImageTextPhone = () => {
  const [phoneContentType] = useState('image')
  const [buttonsVisible] = useState(true)

  return (
    <section className="imageTextPhone">
      <div className="imageTextPhone__image">
        <div className="imageTextPhone__imageBox">
          <img src={image} srcSet="" alt="GuitarTuna" />
        </div>
        <div className="imageTextPhone__phone" data-aspect-ratio="0.48">
          <img
            className="imageTextPhone__phone"
            src={phoneCase}
            srcSet=""
            alt="Phone case"
          />
          {phoneContentType === 'image' ? (
            <img
              className="imageTextPhone__phoneContent"
              src={phoneImage}
              srcSet=""
              alt="GuitarTuna"
            />
          ) : (
            <video
              className="imageTextPhone__phoneContent"
              poster=""
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source src="" type="video/mp4"></source>
            </video>
          )}
        </div>
      </div>
      <div className="imageTextPhone__textContainer">
        <div className="imageTextPhone__textArea">
          <h2 className="imageTextPhone__heading">
            Play your favorite songs on guitar
          </h2>
          <div className="imageTextPhone__text">
            <p>
              Explore thousands of popular songs by top artists. Choose
              simplified or original versions and play at your own pace as songs
              sync with you. Discover something new with songs added daily.
            </p>
          </div>
          {buttonsVisible && (
            <AppDownloadButtons
              tracking={{
                position: 'image-text-phone',
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageTextPhone
