import React, { useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import starFilled from '../../assets/images/star-filled.svg'
import starEmpty from '../../assets/images/star-empty.svg'
import arrowLeft from '../../assets/images/arrow-full-left.svg'
import arrowRight from '../../assets/images/arrow-full-right.svg'
import './index.scss'

const data = [
  {
    stars: 5,
    heading: 'Best Tuning App Ever',
    author: 'MaddProfessor',
    content:
      'I’ve downloaded many tuning apps before and this one is by far the best. Easy to use, super user-friendly, and they have a bunch of fun different chords and songs to help you learn new music. I highly recommend.',
  },
  {
    stars: 5,
    heading: 'Great to tune and play',
    author: 'Lynae T',
    content:
      'I love how easy it is to tune and find and play my favorite songs.',
  },
  {
    stars: 5,
    heading: 'Well-Designed App',
    author: 'Rose the Black Cat',
    content:
      'I mostly use this for tuning my guitar, which this app makes ridiculously easy. It also has a large selection of songs to play along to and it helps show you which chords to play as well how to make the shapes with your fingers.',
  },
  {
    stars: 5,
    heading: 'Got me back into playing',
    author: 'Tuliketsune',
    content:
      "I got this app to tune an old guitar, and noticed it has a feature that autoscrolls as I play chords to famous songs. For some reason, this was a lot more motivating for me than any other way of practicing guitar. Now I'm actually practicing and remembering the names of chords 🙂",
  },
  {
    stars: 5,
    heading: 'Secret weapon',
    author: 'Brad',
    content:
      "Not only is it a tuner (best app tuner I've used personally) but it also has a metronome, games, chords, and a plethora of instruments to choose from and it's SUPER easy to navigate. I love it! Thanks for making an awesome app. From one musician to another, THANKS!!!",
  },
  {
    stars: 5,
    heading: 'Very reliable tuner',
    author: 'theNewPaul',
    content:
      'This is a great app! Very easy to use and very reliable, if you are just starting out, you can take a big worry off your mind, by getting in tune quickly and easily with this app. Highly recommended!',
  },
  {
    stars: 5,
    heading: 'This is the best guitar tuner ever.',
    author: 'Caleb Schafli',
    content:
      'It tunes your guitar accurately so you can get the best sound. I like all the other little games as well because they teach you how to play different chords and you can learn to identify the chords by listening to them and it is all offline.',
  },
]

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: () => {
        return window.innerWidth / 650
      },
      origin: 'center',
    },
    initial: 1,
    loop: true,
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 1,
          spacing: 0,
          origin: 'center',
        },
      },
      '(min-width: 1600px)': {
        slides: {
          perView: () => {
            return window.innerWidth / 800
          },
          origin: 'center',
        },
      },
    },
    created() {
      setLoaded(true)
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  return (
    <section className="slider">
      <h2 className="slider__heading">Trusted by guitar players worldwide</h2>
      <ul ref={sliderRef} className="slider__slider keen-slider">
        {data.map((slide, i) => {
          return (
            <li
              key={i}
              className={`slider__slide keen-slider__slide number-slide${
                i + 1
              }`}
            >
              <article
                className={`slider__slideCard ${
                  i === currentSlide ? '-active' : '-inactive'
                }`}
              >
                {slide.stars && (
                  <span className="slider__stars">
                    {[...Array(slide.stars)].map((star, i) => {
                      return (
                        <img
                          key={i}
                          className="slider__star"
                          src={starFilled}
                          alt="Filled star"
                        />
                      )
                    })}
                    {slide.stars < 5 &&
                      [...Array(5 - slide.stars)].map((star, i) => {
                        return (
                          <img
                            key={i}
                            className="slider__star"
                            src={starEmpty}
                            alt="Empty star"
                          />
                        )
                      })}
                  </span>
                )}
                <h3 className="slider__slideHeading">{slide.heading}</h3>
                <span className="slider__slideAuthor">{slide.author}</span>
                <p className="slider__slideQuote">"</p>
                <p className="slider__slideContent">{slide.content}</p>
              </article>
            </li>
          )
        })}
      </ul>
      {loaded && instanceRef.current && (
        <div className="slider__dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <div
                className={`slider__dot ${
                  currentSlide === idx ? '-active' : ''
                }`}
                key={idx}
                onClick={() => {
                  instanceRef.current.moveToIdx(idx)
                }}
              ></div>
            )
          })}
        </div>
      )}
      {loaded && instanceRef.current && (
        <div className="slider__controls">
          <button
            className={`slider__control -left`}
            onClick={(e) => e.stopPropagation() || instanceRef.current.prev()}
          >
            <img src={arrowLeft} alt="Move to previous" />
          </button>
          <button
            className={`slider__control -right`}
            onClick={(e) => e.stopPropagation() || instanceRef.current.next()}
          >
            <img src={arrowRight} alt="Move to next" />
          </button>
        </div>
      )}
    </section>
  )
}

export default Slider
