import React from 'react'
import './index.scss'

const data = [
  {
    text: '#1 tuner',
    subtext: 'in AppStore & Google Play',
  },
  {
    text: '4.8+',
    subtext: 'AppStore & Google Play rating',
  },
  {
    text: '140M',
    subtext: 'Downloads worldwide',
  },
  {
    text: '100+',
    subtext: 'Tunings available',
  },
]

const NumberStats = () => {
  return (
    <section className="numberStats">
      <h2 className="numberStats__heading">
        Join millions playing with GuitarTuna
      </h2>
      <ul className="numberStats__items" data-amount={data.length}>
        {data.map((item, i) => {
          return (
            <li key={i} className="numberStats__item">
              <span className="numberStats__mainText">{item.text}</span>
              <span className="numberStats__subText">{item.subtext}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default NumberStats
