import React from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import pickIcon from '../../assets/images/guitartuna-pick-icon.svg'
import './index.scss'

const DownloadLinks = () => {
  return (
    <section className="downloadLinks">
      <div className="downloadLinks__wrapper">
        <div className="downloadLinks__icon">
          <img src={pickIcon} alt="GuitarTuna icon" />
        </div>
        <div className="downloadLinks__text">
          <p>
            Get in tune and play songs you love with GuitarTuna. Download today
            on iOS or Android.
          </p>
        </div>
        <div className="downloadLinks__cta">
          <AppDownloadButtons
            tracking={{
              position: 'download-links',
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default DownloadLinks
