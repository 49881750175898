import React, { useState } from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import image from '../../assets/images/guitartuna-pick.svg'
import './index.scss'

const PickImageLinks = () => {
  const [buttonsVisible] = useState(false)

  return (
    <section className="pickImageLinks">
      <h2 className="pickImageLinks__heading">Ready to tune and play?</h2>
      <div className="pickImageLinks__text">
        <p>
          Download GuitarTuna today to tune, practice, and play the songs you
          love.
        </p>
      </div>
      <div className="pickImageLinks__image">
        <div className="pickImageLinks__imageBox">
          <img src={image} srcSet="" alt="GuitarTuna" />
        </div>
      </div>
      {buttonsVisible && (
        <AppDownloadButtons
          tracking={{
            position: 'pick-image-links',
          }}
        />
      )}
    </section>
  )
}

export default PickImageLinks
