export const color = {
  white: '#ffffff',
  green: '#00d296',
  greenYS: '#6fc13e',
  greenAccent: '#00d296',
  orange: '#ee7d54',
  greydark: '#1b1c1e',
  greysemidark: '#2e2f34',
  greymediumdark: '#3e3f43',
  greymiddle: '#585a5f',
  greymiddlelight: '#acb1be',
  greysemilight: '#8c919d',
  greysuperlight: '#f7f8fb',
  greylight: '#e9eaef',
  red: '#f86262',
  darkRed: '#8A2805',
  yellow: '#F6C863',
}

export default color
