import React, { useState, useEffect } from 'react'
import CONFIG, {
    DOWNLOAD_LINK,
    GUITAR_TUNER,
    UKULELE_TUNER,
    VIOLIN_TUNER,
    BASS_TUNER,
    MANDOLIN_TUNER,
    BANJO_TUNER,
    SONG_CHORDS,
} from '../../config/current'
import GuitarTunaLogo from '../../assets/images/guitartuna-logo.svg'
import GuitarTunaIcon from '../../assets/images/guitartuna-pick.svg'

import Button from '../Button'
import './index.scss'

const Header = () => {
    const mobileBreakPoint = 1024
    const viewPortWidth = useWindowSize()
    const [menuActive, setMenuActive] = useState(false)
    const [dropdownActive, setDropdownActive] = useState(false)

    const handleClick = (ev) => {
        const link = ev.target
        const parent = link.closest('.header__menuDropdown')
        const content = parent.querySelector('.header__menuList')

        const toggle = (el) => {
            const first = el.getBoundingClientRect()
            parent.classList.toggle('-active')
            const last = el.getBoundingClientRect()

            el.animate(
                [
                    { height: `${first.height}px` },
                    { height: `${last.height}px` },
                ],
                {
                    duration: 200,
                    easing: 'ease-in-out',
                    iterations: 1,
                },
            )

            setDropdownActive(!dropdownActive)
            link.setAttribute(
                'aria-expanded',
                link.getAttribute('aria-expanded') === 'false'
                    ? 'true'
                    : 'false',
            )
        }

        toggle(content)
    }

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState(undefined)
        useEffect(() => {
            function handleResize() {
                setWindowSize(window.innerWidth)
            }
            window.addEventListener('resize', handleResize)
            handleResize()
            return () => window.removeEventListener('resize', handleResize)
        }, [])
        return windowSize
    }

    useEffect(() => {
        if (menuActive) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [menuActive])

    return (
        <header className="header">
            <nav className="header__nav">
                <a className="header__logo" href={CONFIG.frontAddress}>
                    <img
                        className="header__logoImage"
                        src={GuitarTunaLogo}
                        alt="GuitarTuna"
                    />
                    <img
                        className="header__smallLogoImage"
                        src={GuitarTunaIcon}
                        alt="GuitarTuna"
                    />
                </a>
                <ul className={`header__menu ${menuActive ? '-active' : ''} `}>
                    <li className="header__menuItem">
                        <a href={CONFIG.frontAddress}>Online guitar tuner</a>
                    </li>
                    <li
                        className={`header__menuItem header__menuDropdown ${
                            dropdownActive ? '-active' : ''
                        }`}
                        onClick={
                            viewPortWidth < mobileBreakPoint
                                ? (e) => handleClick(e)
                                : () => void 0
                        }
                        onMouseEnter={
                            viewPortWidth >= mobileBreakPoint
                                ? (e) => handleClick(e)
                                : () => void 0
                        }
                        onMouseLeave={
                            viewPortWidth >= mobileBreakPoint
                                ? (e) => handleClick(e)
                                : () => void 0
                        }
                    >
                        <p id="header__dropdownToggle">
                            Instruments
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M2 6l8 8 8-8"
                                    strokeWidth="3"
                                    fill="transparent"
                                    stroke="#383635"
                                ></path>
                            </svg>
                        </p>
                        <ul
                            className="header__menuList"
                            role="listbox"
                            aria-labelledby="header__dropdownToggle"
                            aria-expanded="false"
                        >
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={GUITAR_TUNER}
                                >
                                    Guitar
                                </a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={UKULELE_TUNER}
                                >
                                    Ukulele
                                </a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={BASS_TUNER}
                                >
                                    Bass
                                </a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={VIOLIN_TUNER}
                                >
                                    Violin
                                </a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={MANDOLIN_TUNER}
                                >
                                    Mandolin
                                </a>
                            </li>
                            <li>
                                <a
                                    onTouchEnd={(e) => e.stopPropagation()}
                                    href={BANJO_TUNER}
                                >
                                    Banjo
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="header__menuItem">
                        <a href={SONG_CHORDS}>Chords</a>
                    </li>
                </ul>
                <Button
                    className="header__CTA"
                    href={DOWNLOAD_LINK}
                    tracking={{
                        label: 'get-the-app',
                        name: 'get-the-app',
                        type: 'button',
                        position: 'header',
                    }}
                >
                    Get the app
                </Button>
                <div
                    className={`header__hamburger ${
                        menuActive ? '-active' : ''
                    } `}
                    onClick={() => {
                        setMenuActive(!menuActive)
                    }}
                ></div>
            </nav>
        </header>
    )
}

export default Header
