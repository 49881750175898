import React from 'react'
import metronome from '../../assets/images/pick-metronome.svg'
import games from '../../assets/images/pick-games.svg'
import tuner from '../../assets/images/pick-tuner.svg'
import note from '../../assets/images/pick-note.svg'
import './index.scss'

const data = [
  {
    image: metronome,
    text: 'Metronome',
    subtext: 'With tap-for-tempo and multiple time signatures.',
  },
  {
    image: games,
    text: 'Chord games',
    subtext: 'Plus chords library, trainers, and more.',
  },
  {
    image: tuner,
    text: 'Chromatic tuner',
    subtext:
      'With alternative and custom tunings, and manual mode to tune by ear.',
  },
  {
    image: note,
    text: '15 instruments',
    subtext:
      'Tuning for stringed instruments including ukulele, bass, banjo, and more.',
  },
]

const Picks = () => {
  return (
    <section className="repeaterPicks">
      <h2 className="repeaterPicks__heading">GuitarTuna app features</h2>
      <ul className="repeaterPicks__items" data-amount={data.length}>
        {data.map((item, i) => {
          return (
            <li key={i} className="repeaterPicks__item">
              <img
                className="repeaterPicks__itemIcon"
                src={item.image}
                alt={item.text}
              />
              <span className="repeaterPicks__itemHeading">{item.text}</span>
              <span className="repeaterPicks__itemText">{item.subtext}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default Picks
