import React, { useState } from 'react'
import AppDownloadButtons from '../AppDownloadButtons'
import phoneCase from '../../assets/images/phone_border-vert.png'
import phoneVideo from '../../assets/images/video.mp4'
import image from '../../assets/images/image.png'
import pickIcon from '../../assets/images/guitartuna-pick-icon.svg'
import './index.scss'

const HeroImage = () => {
  const [buttonsVisible] = useState(true)
  const [phoneVisible] = useState(true)
  const [phoneContentType] = useState('video')
  const [imageVisible] = useState(false)
  const [iconVisible] = useState(false)

  return (
    <section
      className={`heroImage ${!imageVisible ? '-noImage' : ''} ${
        !phoneVisible ? '-noPhone' : ''
      }`}
    >
      <div className="heroImage__container">
        <div className="heroImage__textContainer">
          <div className="heroImage__textArea">
            <h2 className="heroImage__heading">Tune and play all in one app</h2>
            <div className="heroImage__text">
              <p>
                Download GuitarTuna to get in perfect tune and play songs you
                love with chords, tabs, and lyrics. Start for free.
              </p>
            </div>
            {buttonsVisible && (
              <AppDownloadButtons
                tracking={{
                  position: 'hero-image',
                }}
              />
            )}
          </div>
        </div>
        <div className="heroImage__imageArea">
          {phoneVisible && (
            <div className="heroImage__phone" data-aspect-ratio="0.48">
              <img
                className="heroImage__phoneCase"
                src={phoneCase}
                srcSet=""
                alt="Phone case"
              />
              {phoneContentType === 'image' ? (
                <img
                  className="heroImage__phoneContent"
                  src=""
                  srcSet=""
                  alt="GuitarTuna"
                />
              ) : (
                <video
                  className="heroImage__phoneContent"
                  poster=""
                  playsInline={true}
                  autoPlay={true}
                  muted={true}
                  loop={true}
                >
                  <source src={phoneVideo} type="video/mp4" />
                </video>
              )}
            </div>
          )}
          {imageVisible && (
            <div className="heroImage__imageBox">
              <img
                className="heroImage__image"
                src={image}
                srcSet=""
                alt="GuitarTuna"
              />
            </div>
          )}
        </div>
        {iconVisible && (!phoneVisible || !imageVisible) && (
          <img
            className="heroImage__icon"
            src={pickIcon}
            alt="GuitarTuna icon"
          />
        )}
      </div>
    </section>
  )
}

export default HeroImage
