import React, { useState, useEffect } from 'react'
import Post from './Post'
import fallBackPosts from './posts.json'
import { BLOG_POSTS } from '../../config/current'
import './index.scss'

const Blog = () => {
  const [posts, setPosts] = useState([])

  const getPostsFromWP = async () => {
    const url = BLOG_POSTS
    try {
      const response = await fetch(url)
      if (!response.ok) {
        setPosts(fallBackPosts)
      } else {
        let loadedPosts = await response.json()
        setPosts(loadedPosts)
      }
    } catch (error) {
      setPosts(fallBackPosts)
    }
  }

  useEffect(() => {
    getPostsFromWP()
  }, [])

  return (
    <section className="blog">
      <h2 className="blog__heading">Guitar tuning & playing tips</h2>
      <ul className="blog__posts">
        {posts.map((post, i) => {
          return <Post key={i} post={post} />
        })}
      </ul>
    </section>
  )
}

export default Blog
