import React from 'react'
import styled from 'styled-components'
import Dialog from './Dialog'
import icon1x from '../../assets/images/permission.png'
import icon2x from '../../assets/images/permission@2x.png'
import icon3x from '../../assets/images/permission@3x.png'

const PermissionImage = styled.img`
  position: relative;
  transform: translateX(16%);
  margin-bottom: 1rem;
`

const PermissionsDialog = ({ onClick }) => {
  return (
    <Dialog
      id="permission-dialog"
      header={{
        showCloseButton: false,
      }}
      actions={{
        primary: {
          label: 'Sounds good',
          onClick,
        },
      }}
    >
      <PermissionImage
        src={icon1x}
        srcSet={`${icon1x} 1x, ${icon2x} 2x, ${icon3x} 3x`}
        alt="GuitarTuna"
      />
      <h2>Microphone access</h2>
      <p>
        So we can get started, we’ll need to hear your instrument to give
        guidance while you play.
      </p>
    </Dialog>
  )
}

export default PermissionsDialog
