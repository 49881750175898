export const fixAspectRatios = () => {
  /* 
    This is needed as a polyfill to pre-2021 Safari and iOS, as they don't support aspect-ratio.
    Function takes value from a data-attribute and forces width to match height with correct ratio. 
    Only applies to browsers that don't support the aspect-ratio CSS property. 
    Also includes FireFox as a quick fix because Firefox needs a width attribute for the phones.
    */

  const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  if (CSS.supports('aspect-ratio', '1 / 1') && !isFireFox) return

  let items = document.querySelectorAll('div[data-aspect-ratio]')
  for (let i = 0; items.length > i; i++) {
    const aspectRatio = items[i].getAttribute('data-aspect-ratio')
    items[i].style.width = items[i].offsetHeight * aspectRatio + 'px'
  }
  return
}
