import React from 'react'
import styled from 'styled-components'
import { DOWNLOAD_LINK } from '../../config/current'
import { color } from '../Tuner/constants/colors'
import Button from '../Button'
import icon from '../../assets/images/guitartuna-pick-icon.svg'

const SetModalHeight = styled.div`
  height: Max(400px, calc(55vh - 60px));
  width: 100%;
`

const Root = styled.div`
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: ${color.greydark};
  color: #fff;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  z-index: 2000;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;

  button {
    font-size: 1.5rem;
    color: ${color.greymiddle};
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    &::before {
      content: '\u2715';
    }
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: -60px;

  img {
    width: 70%;
    max-width: 120px;
  }

  h2 {
    margin: 0;
    line-height: 1.25;
  }

  p {
    max-width: 500px;
    text-align: center;
    margin-bottom: 2em;
  }
`

const DLButton = styled(Button)`
  padding: 0.5rem 2rem;
`

const GTLogo = styled.img`
  margin-bottom: 1rem;
`

const DownloadScreen = ({ open }) => {
  const buttonLabel = 'Get the app'
  return (
    <>
      <SetModalHeight />
      <Root>
        <Header>
          <button onClick={() => open(false)} />
        </Header>
        <Content>
          <GTLogo src={icon} alt="GuitarTuna logo" />
          <h2>
            Download the app today and <br />
            start tuning for free
          </h2>
          <p>
            Get access to 100+ tunings across 15 instruments, plus chords,
            metronome and more.
          </p>
          <DLButton
            href={DOWNLOAD_LINK}
            tracking={{
              label: buttonLabel,
              name: buttonLabel,
              type: 'button',
              position: 'tuning-download-prompt',
            }}
          >
            Get the app
          </DLButton>
        </Content>
      </Root>
    </>
  )
}

export default DownloadScreen
