import React from 'react'

const Post = ({ post: { images, permalink, title, categories } }) => {
  function getCategoryColor(c) {
    switch (c) {
      case 'How To Guides':
        return '#6559f3' // purple
      case 'Explainer':
        return '#ee7d54' // orange
      default:
        return 'inherit'
    }
  }

  return (
    <li className="blog__post">
      <div className="blog__postImage">
        <img
          src={images.w300}
          srcSet={images.srcset}
          loading="lazy"
          decoding="async"
          alt="Yousician"
        />
      </div>
      <div>
        {categories.map((c, i) => {
          return (
            <a
              key={i}
              className="blog__postCategory"
              href={c.link}
              style={{ color: getCategoryColor(c.title) }}
            >
              {c.title}
            </a>
          )
        })}
        <h3 className="blog__postTitle">
          <a href={permalink}>{title}</a>
        </h3>
        <span>Read more</span>
      </div>
    </li>
  )
}

export default Post
